import { graphql } from "gatsby"
import React from "react"
import { slugify } from "../../../utils"
import TabContent from "../../molecules/TabContent"
import DatoContentModules from "../DatoContentModules"
//import * as styles from "./DatoTabularContent.module.scss"

const DatoTabularContent = (props: Queries.DatoTabularContentFragment) => {
  //console.log('Rendering DatoTabularContent', props)
  const tabs = props.tabs?.map(tab => {
    if (!tab?.modules) return
    return {
      title: tab.title,
      slug: slugify(`tab ${tab.title}`),
      content: <DatoContentModules modules={tab.modules} />,
    }
  })

  if (tabs === undefined) {
    return null
  }

  return <TabContent tabs={tabs} />
}

export default DatoTabularContent

export const query = graphql`
  fragment DatoTabularContent on DatoCmsTabularContent {
    id: originalId # Korjaus daton bugiin, jos käytetään structured textissä
    tabs {
      title
      ...DatoSharedModularContentModules
    }
  }
`
