import React, { ReactNode, useEffect } from "react"
import { isBrowser } from "../../utils"
import * as styles from "./TabContent.module.scss"

type Tab = { title: string | null; slug?: string; content: ReactNode }

function isTab(tab: Tab | null | undefined): tab is Tab {
  return tab !== null
}

export type TabContentProps = { tabs: (Tab | undefined)[] }

const TabContent = (props: TabContentProps) => {
  //console.log('Rendering TabContent', props)

  // check location.hash, to see if we should open a tab initially
  const hash = isBrowser && location.hash
  const initTab = hash && ~props.tabs.findIndex(tab => "#" + tab?.slug === hash)
  const [curTab, setCurTab] = React.useState(initTab ? -initTab - 1 : 0)

  const container = React.useRef<HTMLDivElement>(null)
  // if (props.tabs) setCurTab(0)
  const tabs = props.tabs.filter(isTab)

  useEffect(() => {
    const hashChange = () => {
      const hash = location.hash
      const tab = hash && ~props.tabs.findIndex(tab => "#" + tab?.slug === hash)
      if (tab) {
        setCurTab(-tab - 1)
        container.current && container.current.scrollIntoView({ behavior: "smooth" })
      }
    }

    window.addEventListener("hashchange", hashChange)

    return () => {
      window.removeEventListener("hashchange", hashChange)
    }
  }, [])

  return (
    <div ref={container} className={styles.container}>
      <div className={styles.tabs}>
        {/* tabs */}
        {tabs.map((tab, i) => {
          return (
            <button
              className={`${styles.tab} ${curTab == i ? styles.active : ""}`}
              onClick={() => setCurTab(i)}
              key={`tabs-${i}`}>
              {tab.title}
            </button>
          )
        })}
      </div>
      <div className={styles.content}>
        {!isBrowser
          ? /* ssr-available Content, render all */
            tabs.map((tab, i) => {
              return (
                <div key={`content-${i}`} id={tab.slug} style={{ display: curTab == i ? "block" : "none" }}>
                  {tab.content}
                </div>
              )
            })
          : /* browser Content */
            tabs[curTab] && <div id={tabs[curTab].slug}>{tabs[curTab].content}</div>}
      </div>
    </div>
  )
}

export default TabContent
